import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-title texto-rosa"},[_vm._v("Contáctanos")]),_c('p',{staticClass:"mt-n8"},[_vm._v(" Estamos a su disposición para resolver cualquier tipo de duda ")]),_c(VLayout,[_c(VFlex,{attrs:{"xs12":"","sm8":"","offset-sm2":""}},[_c(VContainer,{attrs:{"grid-list-sm":"","fluid":""}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{attrs:{"md":"6"}},[_c(VCol,[_c(VTextField,{attrs:{"rules":_vm.from_nameRules,"outlined":"","color":"#ea5076","label":"Nombre","required":""},model:{value:(_vm.from_name),callback:function ($$v) {_vm.from_name=$$v},expression:"from_name"}})],1),_c(VCol,[_c(VTextField,{attrs:{"rules":_vm.from_emailRules,"outlined":"","color":"#ea5076","label":"E-mail","required":""},model:{value:(_vm.from_email),callback:function ($$v) {_vm.from_email=$$v},expression:"from_email"}})],1)],1),_c(VTextarea,{attrs:{"rules":_vm.messageRules,"outlined":"","color":"#ea5076","label":"Mensaje","value":"","required":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c(VAlert,{attrs:{"value":_vm.alert,"type":"success","dismissible":"","color":"#ea5076","transition":"scale-transition"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" El correo se ha enviado con éxito ")]),_c(VBtn,{staticClass:"white--text",attrs:{"type":"submit","color":"#ea5076","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.submit},nativeOn:{"click":function($event){_vm.loader = 'loading'}}},[_vm._v(" enviar ")])],1)],1),_c('p',{staticClass:"texto-rosa"},[_vm._v("contacto@additionrh.com")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }